import Image from 'next/image'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import React, { useState } from 'react'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import { TOGGLE_TEST } from 'utils/constant/toggles'
import { useAuth } from 'utils/hooks/useAuth'
import { isToggleEnabled } from 'utils/toggles'

const Login = (props) => {
  const router = useRouter()
  const { usrSession, isUnauthorized } = useAuth()
  const [showWarning, setShowWarning] = useState(false)

  React.useEffect(() => {
    if (isUnauthorized) {
      setShowWarning(isUnauthorized)
    }
  }, [isUnauthorized])

  if (usrSession) {
    router.replace({ pathname: '/home' })
    return null
  }

  let bannerIcon = <Icon className="mt-[2] pr-8">account_circle</Icon>
  if (props.isEnabled) {
    bannerIcon = <Icon className="mt-[2] pr-8">warning</Icon>
  }

  return (
    <>
      <div className="flex h-screen flex-col bg-[#FAFAFA]">
        {/* Header */}
        <div className="flex w-full justify-center bg-blue-90 py-3">
          <span className="text-md font-bold text-white">
            Gunakan komputer/laptop untuk navigasi yang lebih mudah
          </span>
        </div>
        {/* Content */}
        <div className="flex grow items-center justify-center">
          {/* Card */}
          <div className="w-[1140] rounded-lg border border-[#53575A] bg-white px-16 py-8">
            {/* Banner */}
            <div className="flex rounded-md border-2 border-[#FFC453] bg-[#FFDFA3] p-3">
              {bannerIcon}
              <div>
                <Text variant="body">
                  Akses terbatas. Hanya diberikan kepada pihak yang berwenang.
                  Pastikan Anda telah memiliki akses pada platform ini.
                  <br /> Peretasan pada platform ini merupakan pelanggaran dan
                  dapat diberikan sanksi berat oleh pemerintah.
                </Text>
              </div>
            </div>
            {/* Login & Ilustration */}
            <div className="flex w-full pt-8">
              <div className="flex w-1/3 items-center">
                <div className="w-full">
                  <div className="text-base font-bold">
                    Website Unit Layanan Terpadu
                  </div>
                  <div className="py-4 text-2xl font-bold">Masuk Website</div>
                  <Button
                    color="blue"
                    fullWidth
                    className="mb-3"
                    onClick={() => signIn('google')}
                  >
                    <div className="flex w-full justify-center">
                      <div className="flex items-center">
                        <Image
                          src="/images/icon-login-google.png"
                          width={25}
                          height={25}
                          alt="image-icon-login"
                        />
                        <span className="ml-8 text-xs">
                          Masuk dengan Google
                        </span>
                      </div>
                    </div>
                  </Button>
                  <div className={!showWarning ? 'hidden' : ''}>
                    <div className="flex rounded-md border-2 border-[#C82236] bg-[#FFE9EA] p-3">
                      <Icon className="mt-[2] pr-8" color="critical">
                        report
                      </Icon>
                      <div className="w-[256px] text-[#E02D38]">
                        Akun Anda tidak terdaftar di database kami, silahkan
                        hubungi staff yang bersangkutan.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex grow justify-end">
                <Image
                  src="/images/login-Illustration.svg"
                  width="573"
                  height="322"
                  alt="illustration-login"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="flex justify-around bg-white py-4 shadow-[0px_-1px_0px_0px_#C9CBCF]">
          <div className="flex">
            <div>
              <Image
                src="/images/kemdikbud-logo.jpg"
                width={79}
                height={86}
                alt="image-icon-login"
              />
            </div>
            <div className="p-4">
              <div className="pb-2 text-sm font-bold">Dipersembahkan Oleh:</div>
              <div className="text-sm">
                Kementerian Pendidikan
                <br /> dan Kebudayaan
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Text color="informational" variant="body-sm">
              Privasi
            </Text>
            <Text className="px-8" color="informational" variant="body-sm">
              Persyaratan
            </Text>
            <Text color="informational" variant="body-sm">
              Website Kemendikbud
            </Text>
          </div>
        </div>
      </div>
    </>
  )
}

Login.layoutOpts = {
  classNameProp: 'flex justify-center h-screen',
}

export default Login

export async function getServerSideProps() {
  const isEnabled = await isToggleEnabled(TOGGLE_TEST)
  return {
    props: {
      isEnabled,
    },
  }
}
